import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Treatments = ({children}) => (
  <table>
    <tbody>
    {children}
    </tbody>
  </table>
)

const TreatmentHeader = ({id, treatmentName}) => (
  <tr>
    <th id={id}>{treatmentName}</th>
    <th>Single Treatment</th>
  </tr>
)

const TreatmentPrice = ({caption, price}) => (
  <tr>
    <td>{caption}</td>
    <td>{price}</td>
  </tr>
)

const TreatmentSpacer = () => (
  <tr className="spacer">
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
)

const Page = () => (
  <Layout pageName="treatments-and-prices">
    <SEO title="Treatments & Prices" />
    <div id="primary" className="content-area">
      <main id="main" className="site-main" role="main">
        <article id="post-41" className="post-41 page type-page status-publish hentry">

          <div className="text-flow">
            <p>Dermal fillers and wrinkle softeners costs are dependent on the type and amount used:</p>

            <Treatments>
              <TreatmentHeader id="botulinum-toxin-for-women" treatmentName={<><span className="no-break">Botulinum Toxin</span> <span
                className="no-break">for Women</span></>} />
              <TreatmentPrice caption="1 area" price="£195" />
              <TreatmentPrice caption="2 areas" price="£325" />
              <TreatmentPrice caption="3 areas" price="£470" />

              <TreatmentSpacer />

              <TreatmentHeader id="botulinum-toxin-for-men" treatmentName={<><span className="no-break">Botulinum Toxin</span> <span
                className="no-break">for Men</span></>} />
              <TreatmentPrice caption="1 area" price="£215" />
              <TreatmentPrice caption="2 areas" price="£375" />
              <TreatmentPrice caption="3 areas" price="£500" />

              <TreatmentSpacer />

              <TreatmentHeader id="hyperhidrosis" treatmentName="Hyperhidrosis" />
              <TreatmentPrice caption="Botox for Excessive Sweating" price="£499" />

              <TreatmentSpacer />

              <TreatmentHeader id="dermal-fillers" treatmentName="Dermal / Facial Fillers" />
              <TreatmentPrice caption="1 syringe (0.5ml)" price="£180" />
              <TreatmentPrice caption="Lip enhancement" price="£250" />
              <TreatmentPrice caption="Sad Lines" price="£250" />
              <TreatmentPrice caption="Cheeks – Mild Correction" price="£350" />
              <TreatmentPrice caption="Cheeks – Moderate Correction" price="£575" />
              <TreatmentPrice caption="Cheeks – Severe Correction" price="£800" />
              <TreatmentPrice caption="Nasal Labial Folds" price="£250" />
              <TreatmentPrice caption="Smokers Lines" price="£250" />
              <TreatmentPrice caption="Jowls – Mild Correction" price="£250" />
              <TreatmentPrice caption="Jowls – Moderate Correction" price="£350" />
              <TreatmentPrice caption="Jowls – Severe Correction" price="£450" />
              <TreatmentPrice caption="Chin" price="£250" />

              <TreatmentSpacer />

              <TreatmentPrice caption="Scarring" price="From £250" />
            </Treatments>
          </div>

        </article>
      </main>
    </div>
  </Layout>
)

export default Page